import { render, staticRenderFns } from "./articleList.vue?vue&type=template&id=d80891f4&scoped=true&"
import script from "./articleList.vue?vue&type=script&lang=js&"
export * from "./articleList.vue?vue&type=script&lang=js&"
import style0 from "./articleList.vue?vue&type=style&index=0&id=d80891f4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d80891f4",
  null
  
)

export default component.exports